<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ isEdit ? 'Edit User' : 'Create User' }}</p>
        <button class="delete" aria-label="close" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submitForm">
          <div class="field" style="margin-bottom: 40px;">
            <label class="label">Username</label>
            <div class="control">
              <input class="input" type="text" v-model="localUser.username" :disabled="isEdit">
            </div>
          </div>
          <div class="field" style="margin-bottom: 40px;">
            <label class="label">Email</label>
            <div class="control">
              <input class="input" type="email" v-model="localUser.email">
            </div>
          </div>
          <div class="field" style="margin-bottom: 40px;">
            <label class="label">First Name</label>
            <div class="control">
              <input class="input" type="text" v-model="localUser.first_name">
            </div>
          </div>
          <div class="field" style="margin-bottom: 40px;">
            <label class="label">Last Name</label>
            <div class="control">
              <input class="input" type="text" v-model="localUser.last_name">
            </div>
          </div>
          <div class="field" style="margin-bottom: 40px;">
            <label class="label">Roles</label>
            <div class="control no-arrow">
              <div class="select is-fullwidth no-arrow">
                <select v-model="localUser.access_type">
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="superadmin">Superadmin</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 140px;">
            <label class="label">Permission</label>
            <div class="control no-arrow">
              <div class="select is-fullwidth no-arrow">
                <select v-model="localUser.permissions" multiple required>
                  <option v-for="permission in allPermissions" :key="permission.id" :value="permission.id">{{ permission.description }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 140px;">
            <label class="label">Accounts</label>
            <div class="control no-arrow">
              <div class="select is-fullwidth no-arrow">
                <select v-model="localUser.sender_entities" multiple>
                  <option v-for="entity in allSenderEntities" :key="entity.id" :value="entity.id">{{ entity.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 140px;">
            <label class="label">Store Codes</label>
            <div class="control no-arrow">
              <div class="select is-fullwidth no-arrow">
                <select v-model="localUser.store_code" multiple>
                  <option v-for="store in allStoreCodes" :key="store.id" :value="store.id">{{ store.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="!isEdit" class="field" style="margin-bottom: 140px;">
            <label class="label">Temporary Password</label>
            <div class="control">
              <input class="input" type="password" v-model="localUser.password">
            </div>
          </div>
        </form>
      </section>
      <footer class="buttons modal-card-foot">
        <button class="button is-success" @click="submitForm">{{ isEdit ? 'Update' : 'Create' }}</button>
        <button v-if="isEdit" type="button" class="button is-warning" @click="resetPassword">Reset Password</button>
        <button v-if="isEdit" type="button" class="button is-danger" @click="confirmDeleteUser">Delete User</button>
        <button class="button" @click="$emit('close')">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isActive: Boolean,
    isEdit: Boolean,
    user: Object,
    allPermissions: Array,
    allSenderEntities: Array,
    allStoreCodes: Array,
  },
  data() {
    return {
      localUser: this.user
        ? { ...this.user }
        : {
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            sender_entities: [],
            store_code: [],
            permissions: [],
            password: '',
          },
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        console.log('User prop changed:', newUser);
        this.localUser = { ...newUser };
        console.log('Initialized localUser:', this.localUser);
      },
    },
  },
  methods: {
    submitForm() {
      console.log('Submitting form with localUser.permissions:', this.localUser.permissions);
      this.$emit('save', this.localUser);
    },
    resetPassword() {
      axios.post('/api/v1/user/reset-password/', {
        email: this.localUser.email,
      })
      .then(response => {
        console.log('Password reset email sent:', response.data);
        alert('Password reset email sent.');
        window.location.reload();
      })
      .catch(error => {
        console.error('There was a problem with the reset password operation:', error);
        alert('Error sending password reset email.');
      });
    },
    confirmDeleteUser() {
      if (window.confirm('Are you sure you want to delete this user?')) {
        this.deleteUser();
      }
    },
    deleteUser() {
      axios.delete(`/api/v1/user/delete-user/${this.localUser.id}/`)
      .then(response => {
        console.log('User deleted:', response.data);
        alert('User deleted successfully.');
        window.location.reload();
      })
      .catch(error => {
        console.error('There was a problem with the delete user operation:', error);
        alert('Error deleting user.');
      });
    },
  },
};
</script>

<style scoped>
/* Remove the default arrow for select elements */
select.no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding-right: 2em; /* Adjust to make space for your own icon or extra padding */
}

/* Hide the arrow by covering it with a pseudo-element */
.select.no-arrow:after {
  display: none;
}
</style>
