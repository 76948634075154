import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    user: {
      id: '',
      username: '',
      access_type: '',
      email: '',
      first_name: '',
      last_name: '',
      image: '',
      sender_entities: [],
      store_code: [],
      permissions: [] // Added permissions here
    },
    isAuthenticated: false,
    token: '',
    isTemplate: false
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token');
        state.isAuthenticated = true;
        state.user.username = localStorage.getItem('username');
        state.user.id = localStorage.getItem('userid');
        state.user.access_type = localStorage.getItem('access_type');
        state.user.email = localStorage.getItem('email');
        state.user.first_name = localStorage.getItem('first_name');
        state.user.last_name = localStorage.getItem('last_name');
        state.user.image = localStorage.getItem('image');
        state.user.sender_entities = JSON.parse(localStorage.getItem('sender_entities') || '[]');
        state.user.store_code = JSON.parse(localStorage.getItem('store_code') || '[]');
        state.user.permissions = JSON.parse(localStorage.getItem('permissions') || '[]'); // Load permissions from localStorage
      } else {
        state.user.id = '';
        state.user.username = '';
        state.user.email = '';
        state.token = '';
        state.isAuthenticated = false;
        state.user.access_type = '';
        state.user.sender_entities = [];
        state.user.store_code = [];
        state.user.permissions = []; // Clear permissions
      }
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.user.id = '';
      state.user.username = '';
      state.user.email = '';
      state.token = '';
      state.isAuthenticated = false;
      state.user.access_type = ''; 
      state.user.first_name = '';
      state.user.last_name = '';
      state.user.image = '';
      state.user.sender_entities = [];
      state.user.store_code = [];
      state.user.permissions = []; // Clear permissions
      state.user.is_sandbox = '';
    },
    setUser(state, user) {
      state.user.id = user.id;
      state.user.username = user.username;
      state.user.email = user.email;
      state.user.access_type = user.access_type;
      state.user.first_name = user.first_name;
      state.user.last_name = user.last_name;
      state.user.image = user.image;
      state.user.sender_entities = user.sender_entities;
      state.user.store_code = user.store_code;
      state.user.permissions = user.permissions; // Set permissions directly from user data
      state.user.is_sandbox = user.is_sandbox

      localStorage.setItem('username', user.username);
      localStorage.setItem('userid', user.id);
      localStorage.setItem('access_type', user.access_type);
      localStorage.setItem('email', user.email);
      localStorage.setItem('first_name', user.first_name);
      localStorage.setItem('last_name', user.last_name);
      localStorage.setItem('last_name', user.is_sandbox);
      localStorage.setItem('image', user.image);
      localStorage.setItem('sender_entities', JSON.stringify(user.sender_entities));
      localStorage.setItem('store_code', JSON.stringify(user.store_code));
      localStorage.setItem('permissions', JSON.stringify(user.permissions)); // Save permissions in localStorage
    },
    setTemplate(state, isTemplate) {
      state.isTemplate = isTemplate;
    }
  },
  actions: {
    logout({ commit }) {
      commit('removeToken');
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('userid');
      localStorage.removeItem('access_type');
      localStorage.removeItem('email');
      localStorage.removeItem('first_name');
      localStorage.removeItem('last_name');
      localStorage.removeItem('image');
      localStorage.removeItem('sender_entities');
      localStorage.removeItem('store_code');
      localStorage.removeItem('permissions'); // Remove permissions from localStorage
      localStorage.removeItem('is_sandbox');
    },
    setTemplate({ commit }, isTemplate) {
      commit('setTemplate', isTemplate);
    },
    async fetchUserProfile({ commit }) {
      try {
        const response = await axios.get('/api/v1/user-profile/');
        commit('setUser', response.data);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    }
  },
  getters: {
    isSuperAdmin: (state) => state.user.access_type === 'superadmin', 
    canCreateInvoice: (state) => state.user.permissions.includes('can_create_invoice'),
    canEditInvoice: (state) => state.user.permissions.includes('can_edit_invoice'),
    canViewInvoice: (state) => state.user.permissions.includes('can_view_invoice'),
    canExportCsv: (state) => state.user.permissions.includes('can_export_csv'),
    canApproveInvoice: (state) => state.user.permissions.includes('can_approve_invoice'),
    canViewAll: (state) => state.user.permissions.includes('can_view_all'),
  },
  modules: {}
});
