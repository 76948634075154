  <template>
    <div class="page-add-invoice mt-5">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><router-link to="/dashboard">Dashboard</router-link></li>
          <li><router-link to="/dashboard/invoices">Invoices</router-link></li>
          <li class="is-active"><router-link to="/dashboard/invoices/create" aria-current="true">Add</router-link></li>
        </ul>
      </nav>

      <div class="form-container">
        <div class="column is-12">
          <h1 class="title">Add invoice</h1>
        </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Document Selector</p>
          </header>
          <div class="card-content">
            <div class="columns">
              <div class="column is-half">
                <div class="pl-5 mt-2">
                  <label class="switch">
                    <input type="checkbox" v-model="isSelfBilling" @change="toggleSelfBilling">
                    <span class="check"></span>
                    <span class="control-label">Self Billing</span>
                  </label>
                </div>
              </div>
              <div class="column is-half">
                <div class="select pl-5">
                  <select v-model="invoice.invoice_type" @change="handleInvoiceTypeChange">
                    <option value="">- Select Type -</option>
                    <option v-if="!isSelfBilling" value="01">01 - Invoice</option>
                    <option v-if="!isSelfBilling" value="02">02 - Credit Note</option>
                    <option v-if="!isSelfBilling" value="03">03 - Debit Note</option>
                    <option v-if="!isSelfBilling" value="04">04 - Refund Note</option>
                    <option v-if="isSelfBilling" value="11">11 - SelfBilling Invoice</option>
                    <option v-if="isSelfBilling" value="12">12 - SelfBilling Credit Note</option>
                    <option v-if="isSelfBilling" value="13">13 - SelfBilling Debit Note</option>
                    <option v-if="isSelfBilling" value="14">14 - SelfBilling Refund Note</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Company Selector</p>
          </header>
          <div class="card-content">
            <div class="columns pl-4">
              <div class="column is-half">
                <h2 class="is-size-6 mb-1">Supplier</h2>
                <multiselect
                  v-model="invoice.sender"
                  :options="senders"
                  label="name"
                  track-by="entity_code"
                  placeholder="- Select Supplier -"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :allow-empty="true"
                  @search-change="fetchSenders"
                  :custom-label="formatSender"
                />
                <div class="box mt-4 is-size-7" v-if="invoice.sender">
                  <p><strong>{{ invoice.sender.name }}</strong></p>
                  <p><strong>TIN No:</strong> {{ invoice.sender.tin_no }}</p>
                  <p><strong>Identity No ({{ invoice.sender.identity_type }}):</strong> {{ invoice.sender.identity_number }}</p>
                  <p><strong>Email:</strong> {{ invoice.sender.email }}</p>
                  <p><strong>Phone:</strong> {{ invoice.sender.contact_number }}</p>
                  <p v-if="invoice.sender.address1">{{ invoice.sender.address1 }}</p>
                  <p v-if="invoice.sender.address2">{{ invoice.sender.address2 }}</p>
                  <p v-if="invoice.sender.zipcode || invoice.sender.city">{{ invoice.sender.zipcode }} {{ invoice.sender.city }}</p>
                  <p v-if="invoice.sender.country">{{ invoice.sender.country }}</p>
                </div>
              </div>

              <div class="column is-half">
                <h2 class="is-size-6 mb-1">Buyer</h2>
                <multiselect
                  v-model="selectedCustomer"
                  :options="customers"
                  label="name"
                  track-by="entity_code"
                  placeholder="- Select Customer -"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :allow-empty="true"
                  @search-change="fetchCustomers"
                  :custom-label="formatCustomer"
                />
                <div class="box mt-4 is-size-7" v-if="selectedCustomer && selectedCustomer !== 'new'">
                  <p><strong>{{ selectedCustomer.name }}</strong></p>
                  <p><strong>TIN No:</strong> {{ selectedCustomer.tin_no }}</p>
                  <p><strong>Identity No ({{ selectedCustomer.identity_type }}):</strong> {{ selectedCustomer.identity_number }}</p>
                  <p><strong>Email:</strong> {{ selectedCustomer.email }}</p>
                  <p><strong>Phone:</strong> {{ selectedCustomer.contact_number }}</p>
                  <p v-if="selectedCustomer.address1">{{ selectedCustomer.address1 }}</p>
                  <p v-if="selectedCustomer.address2">{{ selectedCustomer.address2 }}</p>
                  <p v-if="selectedCustomer.zipcode || selectedCustomer.city">{{ selectedCustomer.zipcode }} {{ selectedCustomer.city }}</p>
                  <p v-if="selectedCustomer.country">{{ selectedCustomer.country }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Document Header</p>
          </header>
          <div class="columns card-content">
            <div class="column is-6">
              <div class="column is-8">
                <div class="field is-size-7">
                  <label>Invoice Number</label>
                  <div class="control">
                    <input type="text" class="input is-small" v-model="invoice.invoice_number">
                  </div>
                </div>
                <div class="field is-size-7">
                  <label>Invoice Date</label>
                  <div class="control">
                    <input type="date" class="input is-small" v-model="invoice.invoice_date" disabled>
                  </div>
                </div>
                
                <div class="field is-size-7">
                  <label>Currency</label>
                  <div class="control">
                    <div class="select is-small">
                      <select v-model="invoice.invoice_currency" @change="handleCurrencyChange">
                        <option v-for="currency in currencies" :key="currency.code" :value="currency.code">
                          {{ currency.code }} - {{ currency.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div v-if="invoice.invoice_currency !== 'MYR'" class="field is-size-7">
                  <label>Currency Exchange Rate *</label>
                  <div class="control">
                    <input type="number" step="0.0001" class="input is-small" v-model="invoice.currency_exchange_rate" required>
                  </div>
                </div>


                <div class="field is-size-7">
                  <label>Store Code *</label>
                  <div class="select is-small">
                    <select v-model="invoice.sender_store_code">
                      <option v-for="store in userProfile.store_code" :key="store.id" :value="store.code" required>
                        {{ store.code }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>



            <div class="column is-6">
              <div class="column is-8">
                <div class="columns field is-size-7 ">
                  <div class="column">
                    <label>Rounding Amount</label>
                    <div class="control" @change="updateTotals">
                      <input type="number" step="0.01" class="input is-small has-text-right" v-model="invoice.rounding_amount">
                    </div>
                  </div>
                  <div class="column">
                    <label>Pre-payment Amount</label>
                    <div class="control" @change="updateTotals">
                      <input type="number" step="0.01" class="input is-small has-text-right" v-model="invoice.prepayment_amount">
                    </div>
                  </div>
                </div>
                <div v-if="invoice.invoice_type !== '01' && invoice.invoice_type !== '11' && invoice.invoice_type !== ''">
                  <div class="field is-size-7">
                    <label>Original LHDN Invoice UUID *</label>
                    <div class="control">
                      <input type="text" class="input is-small" v-model="invoice.original_inv_ref_number" required>
                    </div>
                  </div>
                  <div class="field is-size-7">
                    <label>Original Seller Invoice No *</label>
                    <div class="control">
                      <input type="text" class="input is-small" v-model="invoice.original_inv_number" required>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Items</p>
          </header>
          <div class="card-content">
            <ItemForm
              v-for="(item, index) in invoice.items"
              :key="item.id"
              :initialItem="item"
              @updateItem="updateItem($event, index)"
              @delete-item="deleteItem"
              :classification-options="classificationOptions"
              :description-options="descriptionOptions"
            ></ItemForm>
            <button class="field button is-small is-info" @click="addItem">+ Add Item</button>
          </div>
        </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Document Summary</p>
          </header>
          <div class="card-content">
            <div class="columns">
              <div class="column is-half">
                <div class="field column is-10">
                  <label>Additional Comments</label>
                    <div class="control is-small">
                      <input type="text" class="input is-small" v-model="invoice.document_remarks">
                    </div>
                </div>
                <div class="field column is-5">
                  <label>Payment Due Date</label>
                  <div class="control">
                    <input type="date" class="input is-small" v-model="invoice.payment_due_date">
                  </div>
                </div>
                <div v-if="invoice.tax_rate == 0" class="field column is-6">
                  <div class="column is-size-3">
                    <div class=" is-size-6">
                      <label>Tax Exemption Reason</label>
                      <div class="control is-small">
                        <input type="text" class="input is-small" v-model="invoice.tax_exemption_reason">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <h2 class="is-size-5 mb-4">Invoice Summary</h2>
                <p><strong>Gross amount</strong>: {{ formatCurrency(invoice.total_excluding_tax) }}</p>
                <p><strong>Discount amount</strong>: {{ formatCurrency(invoice.total_discount_value) }}</p>
                <p><strong>Tax amount</strong>: {{ formatCurrency(invoice.total_tax_amount) }}</p>
                <p v-if="invoice.prepayment_amount !== '0.00'"><strong>Pre-payment Amount</strong>: {{ formatCurrency(invoice.prepayment_amount) }}</p>
                <p v-if="invoice.rounding_amount !== '0.00'"><strong>Rounding Amount</strong>: {{ formatCurrency(invoice.rounding_amount) }}</p>
                <p><strong>Payable amount</strong>: {{ formatCurrency(invoice.total_payable_amount) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons mt-6 ml-6 pl-6">
        <button class="button is-primary" @click="previewInvoice">Preview</button>
        <button class="button is-success ml-3" @click="submitForm">Request Approval</button>
        <button class="button is-info ml-3" @click="showSaveTemplateModal = true">Save as Template</button>
        <div v-if="serverResponse" class="notification is-info">
          {{ serverResponse }}
        </div>
      </div>

      <TemplateModal
        :isVisible="showSaveTemplateModal"
        @close="showSaveTemplateModal = false"
        @save-template="saveTemplate"
      />
    </div>

    <InvoicePreview :showPreview="showPreview" :logo="previewLogo" :sender="invoice.sender" :invoice="invoice" :customer="selectedCustomer === 'new' ? newCustomer : selectedCustomer" @close="showPreview = false" />
  </template>

  <script>
  import axios from 'axios';
  import { toast } from 'bulma-toast';
  import ItemForm from '@/components/CreateInvoice/ItemForm.vue';
  import InvoicePreview from '@/components/CreateInvoice/InvoicePreview.vue';
  import Multiselect from 'vue-multiselect';
  import 'vue-multiselect/dist/vue-multiselect.css';
  import 'bulma-switch/dist/css/bulma-switch.min.css';
  import TemplateModal from '@/components/CreateInvoice/TemplateModal.vue';

  export default {
    name: 'AddInvoice',
    components: {
      ItemForm,
      InvoicePreview,
      Multiselect,
      TemplateModal
    },
    data() {
      return {
        invoice: {
          sender: '',
          customer: '',
          invoice_type: '',
          selfbilling: false,
          invoice_number: '',
          invoice_date: this.formatDate(new Date()),
          payment_due_date: '',
          invoice_time: '',
          invoice_currency: 'MYR',
          currency_exchange_rate: '', // Exchange rate field
          sender_store_code: '', // Initialize as empty
          document_type: '',
          original_inv_number: '',
          original_inv_ref_number: '',
          prepayment_amount: '0.00',
          document_remarks: '',
          items: [
            {
              id: 0,
              classification: '',
              item_description: '',
              unit_price: '0.00',
              quantity: '1.00',
              tax_rate: '0.0',
              tax_type: '06',
              subtotal: '0.00',
              total_excluding_tax: '0.00',
              tax_amount: '0.00',
              discount_rate: '0.00',
              discount_amount: '0.00',
              unit_of_measurement: 'EA',
            },
          ],
          total_excluding_tax: '0.00',
          total_including_tax: '0.00',
          total_net_amount: '0.00',
          total_discount_value: '0.00',
          total_tax_amount: '0.00',
          total_payable_amount: '0.00',
          tax_rate: '0',
          rounding_amount: '0.00',
          tax_exemption_reason: 'Non SST Registrant',
          buyer_name: '',
          buyer_entity_code: '',
          buyer_tin_no: '',
          buyer_identity_type: 'BRN',
          buyer_identity_number: '',
          buyer_sstregistration: '',
          buyer_email: '',
          buyer_address1: '',
          buyer_address2: '',
          buyer_address3: '',
          buyer_zipcode: '',
          buyer_city: '',
          buyer_state: '',
          buyer_country: 'MYS',
          buyer_contact_number: '',
        },
        senders: [],
        customers: [],
        selectedCustomer: '',
        selectedInvoiceType: '',
        serverResponse: '',
        showPreview: false,
        isSelfBilling: false,
        previewLogo: null,
        originalSenders: [],
        originalCustomers: [],
        showSaveTemplateModal: false,
        classificationOptions: [], // New data property
        descriptionOptions: [], // New data property
        currencies: [],
        userProfile: {
          sender_entities: [],
          store_code: [], // Initialized as empty
        },
      };
    },
    computed: {
      isFormValid() {
      return (
        this.invoice.invoice_type &&
        this.invoice.sender &&
        this.invoice.sender.name &&
        this.invoice.customer &&
        this.invoice.customer.name &&
        this.invoice.invoice_number &&
        this.invoice.invoice_date &&
        this.invoice.invoice_currency &&
        (this.invoice.invoice_currency === 'MYR' || this.invoice.currency_exchange_rate) && // Ensure exchange rate is filled for non-MYR currencies
        this.invoice.items.length > 0 &&
        this.invoice.items.every(item => item.item_description && item.unit_price && item.quantity)
      );
    },
      invoice_type: {
        get() {
          return this.selectedInvoiceType.substring(0, 2);
        },
        set(value) {
          this.selectedInvoiceType = value;
          this.invoice.invoice_type = value.substring(0, 2);
        }
      }
    },
    async mounted() {
      await this.getUserProfile(); // Ensure user profile is fetched
      await this.getSenders();
      await this.getClients();
      await this.fetchCurrencies();
      this.setCurrentTime();
    },
    watch: {
      // Watch for changes in userProfile.store_code
      'userProfile.store_code': function (newStoreCode) {
        if (newStoreCode.length === 1) {
          this.invoice.sender_store_code = newStoreCode[0].code;
        }
      },
    },
    methods: {
      async fetchCurrencies() {
        try {
          const response = await axios.get('https://openexchangerates.org/api/currencies.json', {
            params: {
              app_id: 'YOUR_API_KEY' // Replace with your API key
            }
          });
          this.currencies = Object.entries(response.data).map(([code, name]) => ({
            code,
            name
          }));
        } catch (error) {
          console.error('Error fetching currencies:', error);
        }
      },
      formatSender(sender) {
        return sender ? `${sender.name} (${sender.entity_code})` : '';
      },
      formatCustomer(customer) {
        return customer ? `${customer.name} (${customer.entity_code})` : '';
      },
      previewInvoice() {
        // Check if selfbilling is true or false
        if (this.isSelfBilling) {
          // Get the sender_entities from localStorage and parse it
          this.previewLogo = this.selectedCustomer.logo;

        } else {
          // Use the sender logo from the invoice if selfbilling is false
          this.previewLogo = this.invoice.sender.logo;
        }
        // Show the invoice preview modal
        this.showPreview = true;
      },
      handleCurrencyChange() {
        if (this.invoice.invoice_currency === 'MYR') {
          this.invoice.currency_exchange_rate = ''; // Clear exchange rate if MYR is selected
        }
      },
      async getSenders() {
        try {
          // Assume that userProfile is already populated by getUserProfile method
          this.originalSenders = this.userProfile.sender_entities;
          if (!this.isSelfBilling) {
            this.senders = this.originalSenders;
          }
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      },
      async getClients(search = '') {
        try {
          const response = await axios.get('/api/v1/customers/', {
            params: { search }
          });
          this.originalCustomers = response.data;
          if (!this.isSelfBilling) {
            this.customers = this.originalCustomers;
          }
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      },
      async getUserProfile() {
        try {
          // Fetch and parse user profile data from local storage or API
          const senderEntities = JSON.parse(localStorage.getItem('sender_entities')) || [];
          const storeCode = JSON.parse(localStorage.getItem('store_code')) || [];

          // Set the userProfile state
          this.userProfile.sender_entities = senderEntities;
          this.userProfile.store_code = storeCode;
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      },
      toggleSelfBilling() {
        this.invoice.invoice_number = '';
        this.invoice.invoice_type = '';
        if (this.isSelfBilling) {
          this.invoice.tax_type = '06';
          this.invoice.tax_rate = 0;
          this.invoice.tax_exemption_reason = '';
          this.senders = this.originalCustomers;
          this.customers = this.originalSenders;
        } else {
          this.senders = this.originalSenders;
          this.customers = this.originalCustomers;
        }
      },
      handleInvoiceTypeChange() {
          this.generateInvoiceNumber();
      },
      async generateInvoiceNumber() {
        try {
          const response = await axios.get('/api/v1/invoice_number', {
            params: {
              invoice_type: this.invoice.invoice_type
            }
          });
          this.invoice.invoice_number = response.data.invoice_number;
        } catch (error) {
          console.error('Error generating invoice number:', error);
        }
      },
      async submitForm() {
        if (!this.validateForm()) {
          toast({
            message: 'Please complete all mandatory fields.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'bottom-right',
          });
          return;
        }

        if (this.selectedCustomer === 'new') {
          this.invoice.customer = this.newCustomer;
        } else {
          this.invoice.customer = this.selectedCustomer;
        }

        if (this.isSelfBilling) {
          this.invoice.selfbilling = true;
          this.invoice.document_type = 'SB';
          this.invoice.sender_entity_code = this.invoice.sender.entity_code;
        }

        this.invoice.items = this.invoice.items.map((item, index) => ({
          ...item,
          id: this.generateUniqueId(index),
          unit_price: parseFloat(item.unit_price).toFixed(2),
          subtotal: parseFloat(item.subtotal).toFixed(2),
          total_excluding_tax: parseFloat(item.total_excluding_tax).toFixed(2),
          tax_amount: parseFloat(item.tax_amount).toFixed(2),
          discount_amount: parseFloat(item.discount_amount).toFixed(2),
          discount_rate: parseFloat(item.discount_rate).toFixed(2),
        }));

        const formattedInvoiceData = this.formatInvoiceData();
        const requestBody = {
          source: "UI",
          invoices_data: [[formattedInvoiceData, this.invoice.items]]
        };

        try {
          const response = await axios.post('/api/einvoicing_json/', requestBody);
          toast({
            message: 'The invoice was added',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.serverResponse = response.data;
          setTimeout(() => {
            this.$router.push('/dashboard/create-document');
          }, 2000);
        } catch (error) {
          console.log(error);
          this.serverResponse = error.response ? error.response.data : 'Error submitting invoice';
        }
      },
      async saveTemplate(templateName, templateDescription) {
        if (!templateName) {
          toast({
            message: 'Please provide a name for the template.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'bottom-right',
          });
          return;
        }

        if (this.selectedCustomer === 'new') {
          this.invoice.customer = this.newCustomer;
        } else {
          this.invoice.customer = this.selectedCustomer;
        }

        if (this.isSelfBilling) {
          this.invoice.selfbilling = true;
          this.invoice.document_type = 'SB';
          this.invoice.sender_entity_code = this.invoice.sender.tin_no;
        }

        this.invoice.items = this.invoice.items.map((item) => ({
          ...item,
          unit_price: parseFloat(item.unit_price).toFixed(2),
          subtotal: parseFloat(item.subtotal).toFixed(2),
          total_excluding_tax: parseFloat(item.total_excluding_tax).toFixed(2),
          tax_amount: parseFloat(item.tax_amount).toFixed(2),
          discount_amount: parseFloat(item.discount_amount).toFixed(2),
          discount_rate: parseFloat(item.discount_rate).toFixed(2),
        }));

        const formattedInvoiceData = this.formatInvoiceData();
        const userId = localStorage.getItem('userid');
        const templateData = {
          source: "UI",
          selfbilling: this.isSelfBilling,
          description: templateDescription,
          user_id: userId,
          invoices_data: [[formattedInvoiceData, this.invoice.items]]
        };

        const requestBody  = {
            name: templateName,
            data: templateData,
        };

        try {
          await axios.post('/api/v1/invoice-templates/', requestBody);
          toast({
            message: 'Template saved successfully',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          });
          this.showSaveTemplateModal = false;
          this.serverResponse = "Template Saved Successfully";
          setTimeout(() => {
            this.$router.push('/dashboard/create-document');
          }, 1000);
        } catch (error) {
          console.log(error);
          this.serverResponse = error.response ? error.response.data : 'Error saving template';
        }
      },
      validateForm() {
        return (
          this.invoice.invoice_type &&
          this.invoice.sender &&
          (this.selectedCustomer || this.newCustomer.name) &&
          this.invoice.invoice_number &&
          this.invoice.invoice_date &&
          this.invoice.invoice_currency &&
          this.invoice.sender_store_code &&
          this.invoice.items.length > 0
        );
      },
      formatCurrency(value) {
        if (!value) return 'MYR 0.00';
        return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(value);
      },
      formatInvoiceData() {
        return {
          source: "UI",
          approval_status: "Pending Approval",
          invoice_type: this.invoice.invoice_type,
          document_type: this.invoice.document_type,
          invoice_number: this.invoice.invoice_number,
          invoice_date: this.invoice.invoice_date,
          payment_due_date: this.invoice.payment_due_date,
          invoice_time: this.invoice.invoice_time,
          sender_store_code: this.invoice.sender_store_code,
          original_inv_number: this.invoice.original_inv_number,
          original_inv_ref_number: this.invoice.original_inv_ref_number,
          document_remarks: this.invoice.document_remarks,
          prepayment_amount: this.invoice.prepayment_amount,
          selfbilling: this.invoice.selfbilling,
          invoice_currency: this.invoice.invoice_currency,
          currency_exchange_rate: this.invoice.currency_exchange_rate,
          total_excluding_tax: parseFloat(this.invoice.total_excluding_tax),
          total_including_tax: parseFloat(this.invoice.total_including_tax),
          sender_entity_code: this.invoice.sender.entity_code,
          total_payable_amount: parseFloat(this.invoice.total_payable_amount),
          total_net_amount: parseFloat(this.invoice.total_net_amount),
          total_discount_value: parseFloat(this.invoice.total_discount_value),
          total_tax_amount: parseFloat(this.invoice.total_tax_amount),
          rounding_amount: parseFloat(this.invoice.rounding_amount),
          tax_rate: parseFloat(this.invoice.tax_rate),
          tax_type: this.invoice.tax_type,
          buyer_name: this.invoice.customer.name,
          buyer_entity_code: this.invoice.customer.entity_code,
          buyer_tin_no: this.invoice.customer.tin_no,
          buyer_identity_type: this.invoice.customer.identity_type,
          buyer_identity_number: this.invoice.customer.identity_number,
          buyer_sstregistration: this.invoice.customer.sstregistration,
          buyer_email: this.invoice.customer.email,
          buyer_address1: this.invoice.customer.address1,
          buyer_address2: this.invoice.customer.address2,
          buyer_address3: this.invoice.customer.address3,
          buyer_zipcode: this.invoice.customer.zipcode,
          buyer_city: this.invoice.customer.city,
          buyer_state: this.invoice.customer.state,
          buyer_country: this.invoice.customer.country,
          buyer_contact_number: this.invoice.customer.contact_number,
        };
      },
      generateUniqueId(index) {
        const timestamp = Date.now();
        return parseInt(`${timestamp}${index}`);
      },
      addItem() {
        const index = this.invoice.items.length;
        const uniqueId = this.generateUniqueId(index);
        this.invoice.items.push({
          id: uniqueId,
          classification: '',
          item_description: '',
          unit_price: '0.00',
          quantity: '1.00',
          tax_rate: '0.00',
          tax_type: 'Not Applicable',
          subtotal: '0.00',
          total_excluding_tax: '0.00',
          tax_amount: '0.00',
          discount_rate: '0.00',
          discount_amount: '0.00',
          unit_of_measurement: 'EA'
        });
      },
      deleteItem(itemId) {
        this.invoice.items = this.invoice.items.filter(item => item.id !== itemId);
        this.updateTotals();
      },
      updateItem(updatedItem, index) {
        this.invoice.items[index] = updatedItem;
        this.updateTotals();
      },
      updateTotals() {
        let net_amount = 0;
        let tax_amount = 0;
        let disc_amount = 0;

        for (let i = 0; i < this.invoice.items.length; i++) {
          const item = this.invoice.items[i];
          const item_net_amount = parseFloat(item.unit_price) * item.quantity || 0;
          const discount = parseFloat(item.discount_amount) || 0;
          const item_tax_amount = (item_net_amount - discount) * (parseFloat(item.tax_rate) / 100 || 0);

          item.subtotal = item_net_amount.toFixed(2);
          item.total_excluding_tax = (item_net_amount - discount).toFixed(2);
          item.tax_amount = item_tax_amount.toFixed(2);
          item.discount_amount = discount.toFixed(2);

          tax_amount += item_tax_amount;
          net_amount += item_net_amount - discount; // Subtract discount for net amount
          disc_amount += discount;
        }

        const rounding_amount = parseFloat(this.invoice.rounding_amount) || 0;
        const prepayment_amount = parseFloat(this.invoice.prepayment_amount) || 0;

        // Update invoice totals
        this.invoice.total_excluding_tax = net_amount.toFixed(2);
        this.invoice.total_discount_value = disc_amount.toFixed(2);
        this.invoice.total_tax_amount = tax_amount.toFixed(2);
        this.invoice.total_net_amount = (net_amount).toFixed(2); // Already includes discounts
        this.invoice.total_including_tax = (net_amount + tax_amount).toFixed(2);
        this.invoice.total_payable_amount = (net_amount + tax_amount + rounding_amount - prepayment_amount).toFixed(2);

        // Set tax exemption reason to "NOT APPLICABLE" if tax rate is 0
        if (this.invoice.tax_rate === 0) {
          this.invoice.tax_exemption_reason = "NOT APPLICABLE";
        }
      },

      setCurrentTime() {
        const now = new Date();
        const adjustedTime = new Date(now.getTime() - 8 * 60 * 60 * 1000); // Subtract 8 hours

        // Check if the adjusted time is on the same day
        if (now.getDate() !== adjustedTime.getDate()) {
          // If it's not, set the time to 00:00:01
          this.invoice.invoice_time = '00:00:01';
        } else {
          // Otherwise, use the adjusted time
          this.invoice.invoice_time = adjustedTime.toISOString().split('T')[1].split('.')[0];
        }
      },
      formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
      }
    }
  };
  </script>

  <style lang="scss">
  .select, .select select {
    width: 100%;
  }
  .form-container {
    width: 1050px;
  }
  .switch {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .switch input {
    display: none;
  }
  .switch .check {
    width: 40px;
    height: 20px;
    background: #ddd;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
  }
  .switch .check::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
  }
  .switch input:checked + .check {
    background: #48c774;
  }
  .switch input:checked + .check::after {
    left: 20px;
  }
  .switch .control-label {
    margin-left: 10px;
  }
  </style>
