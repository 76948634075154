<template>
  <div class="card left-card">
    <h2 class="title is-size-6" style="display: flex; justify-content: center">Templates</h2>
    <ul>
      <li v-for="template in templates" :key="template.id" class="is-size-7">
        <router-link :to="{ name: 'EditInvoice', params: { uuid: template.id }, query: { isTemplate: true } }">
          {{ template.name }}
        </router-link>
        <button class="button is-small is-danger" @click="confirmDeleteTemplate(template.id)">Delete</button>
      </li>
    </ul>
    <hr class="separator">
    <button class="button is-link is-fullwidth" @click="createTemplate">Create New E-Invoice</button>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
  name: 'CreateOrEditInvoice',
  data() {
    return {
      templates: [],
    };
  },
  methods: {
    fetchTemplates() {
      axios.get('/api/v1/invoice-templates/')
        .then(response => {
          // Assuming the API returns an array of objects with only 'name' keys
          this.templates = response.data.templates || [];
        })
        .catch(error => {
          console.error('Error fetching templates:', error);
          toast({
            message: 'Error fetching templates!',
            type: 'is-danger',
            pauseOnHover: true,
            duration: 5000,
            position: 'top-right'
          });
        });
    },
    createTemplate() {
      this.$router.push({ name: 'AddInvoice' });
    },
    confirmDeleteTemplate(templateId) {
      const confirmation = window.confirm(
        'Are you sure you want to delete this template? Deleted templates cannot be retrieved.'
      );
      if (confirmation) {
        this.deleteTemplate(templateId);
      }
    },
    deleteTemplate(templateId) {
      axios.delete(`/api/v1/invoice-templates/${templateId}/`)
        .then(() => {
          this.fetchTemplates(); // Refresh the list after deletion
          toast({
            message: 'Template deleted successfully!',
            type: 'is-success',
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right'
          });
        })
        .catch(error => {
          console.error('Error deleting template:', error);
          toast({
            message: 'Error deleting template!',
            type: 'is-danger',
            pauseOnHover: true,
            duration: 5000,
            position: 'top-right'
          });
        });
    },
  },
  created() {
    this.fetchTemplates();
  },
};
</script>

<style scoped>
.left-card {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
}

.left-card h2 {
  text-align: left;
}

.left-card ul {
  width: 100%;
  padding: 0;
}

.left-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.separator {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ccc;
  text-align: center;
}

.separator:after {
  content: '------------or------------';
  display: inline-block;
  position: relative;
  top: -0.6em;
  background: #f9f9f9;
  padding: 0 10px;
}

.button.is-fullwidth {
  width: 100%;
}
</style>
